



























































































































































































import { defineComponent, ref } from '@vue/composition-api'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import $http from '@/api'

export default defineComponent({
  name: 'WisdomClassroomPhone',
  components: {
    swiper,
    swiperSlide
  },
  setup() {
    const show = ref(false)
    const PRODUCT = {
      PC: 2, //主讲端
      SCREEN: 1 //大屏端
    }
    const productId = ref(PRODUCT.PC)
    const handleSetShow = (state: boolean) => (show.value = state)

    // 下载助手安装包
    const handleDownload = async (id: number) => {
      productId.value = id
      const { data } = await $http.getDownloadUrl({
        productId: productId.value
      })
      window.location.href = data.downloadUrl
    }
    return {
      show,
      PRODUCT,
      productId,
      handleSetShow,
      handleDownload,
      swiperOption: {
        pagination: { el: '.swiper-pagination' },
        autoplay: false
      }
    }
  }
})
